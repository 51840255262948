<template>
  <div class="yx-phone">
    <div class="login-content">
      <div class="login-title-box">
        <img class="login-image" src="~@/assets/images/yuanxi/logo.png" alt="">
      </div>

      <div class="login-input-box fadeIn" v-if="!is_register && !is_forget && !is_wechat">
        <div class="lp-item">
          <a-input v-model:value="account" class="my-input" placeholder="请输入手机号" :maxLength="20" />
        </div>
        <div class="lp-item">
          <a-input-password v-model:value="password" type="password" placeholder="请输入密码" :maxLength="20" />
        </div>
        <div class="lp-item">
          <a-button class="my-button" type="primary" :loading="logining" @click="login()">登录</a-button>
        </div>
        <div class="lp-item" v-if="device == 'web'">
          <a-button class="my-button wx-button" @click="wechat_login()">
            <img src="~@/assets/images/yuanxi/wechat.png" alt="">
            <span>微信登录</span>
          </a-button>
        </div>

        <div class="lp-info">
          <div class="a-text" @click="is_forget = true">忘记密码？</div>
          <div>
            <span>没有注册？</span>
            <span class="a-text info-register" @click="is_register = true">立即注册</span>
          </div>
        </div>
      </div>

      <!-- 注册 -->
      <div class="login-input-box register-box fadeIn" v-if="is_register">
        <div class="lp-item">
          <a-input v-model:value="formRegister.phone" class="my-input" placeholder="请输入手机号" :maxLength="11" />
        </div>
        <div class="lp-item">

          <div class="code-box">
            <a-input v-model:value="formRegister.code" class="my-input" placeholder="请输入验证码" suffix :maxLength="6" />
            <!-- 倒计时 -->
            <span class="get-code" v-if="count == 0" @click="getPhoneCode">获取验证码</span>
            <span class="get-code" v-else>{{ count }}秒后重试</span>
          </div>
        </div>
        <div class="lp-item">
          <a-input-password v-model:value="formRegister.password" type="password" placeholder="请输入密码" :maxLength="20" />
        </div>
        <div class="lp-item">
          <a-input-password v-model:value="formRegister.checkPassword" type="password" placeholder="请输入再次输入密码"
            :maxLength="20" />
        </div>
        <div class="lp-item">
          <a-input v-model:value="formRegister.nickName" class="my-input" placeholder="请输入姓名" :maxLength="20" />
        </div>
        <a-button class="my-button" type="primary" :loading="registering" @click="checkCode()">确认注册</a-button>
        <div class="a-text" @click="returnLogin()">返回登陆</div>
      </div>
      <!-- 忘记密码 -->
      <div class="login-input-box forget-box fadeIn" v-if="is_forget">
        <div class="fadeIn" v-if="forgetStep == 1">
          <div class="lp-item">
            <a-input v-model:value="formRegister.phone" class="my-input" placeholder="请输入手机号" :maxLength="11" />
          </div>
          <div class="lp-item">
            <div class="code-box">
              <a-input v-model:value="formRegister.code" class="my-input" placeholder="请输入验证码" suffix :maxLength="6" />
              <!-- 倒计时 -->
              <span class="get-code" v-if="count == 0" @click="getPhoneCode">获取验证码</span>
              <span class="get-code" v-else>{{ count }}秒后重试</span>
            </div>
          </div>
          <a-button class="lp-button my-button" type="primary" :loading="registering"
            @click="checkCode()">下一步</a-button>
        </div>
        <div class="fadeIn" v-if="forgetStep == 2">
          <div class="lp-item">
            <a-input-password v-model:value="formRegister.password" type="password" placeholder="请输入密码"
              :maxLength="20" />
          </div>
          <div class="lp-item">
            <a-input-password v-model:value="formRegister.checkPassword" type="password" placeholder="请输入再次输入密码"
              :maxLength="20" />
          </div>
          <a-button class="lp-button my-button" type="primary" :loading="editPasswording"
            @click="editPassword()">确认修改</a-button>
        </div>
        <div class="a-text" @click="returnLogin()">返回登陆</div>
      </div>
    </div>
  </div>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, WX_OPEN_SERVER } from '@/config/url'
import { M_APPID } from '@/config/wx'

export default {
  name: "yx_phone",
  props: {
    redirectURI: String,
  },
  data() {
    return {
      userGuid: '',
      formRegister: {
        phone: '',
        password: '',
        checkPassword: '',
        nickName: '',
      },

      loading: true,
      is_wechat: false,
      is_register: false,
      is_forget: false,

      logining: false,
      registering: false,
      editPasswording: false,

      account: '',
      password: '',

      count: 0,
      forgetStep: 1,
      device: 'web',
      pageContent: null,
    }
  },
  async mounted() {
    document.addEventListener('UniAppJSBridgeReady', () => {
      uni.getEnv((res) => {
        if (res.plus) {
          this.device = 'app'
        }
      });
    });
  },
  methods: {
    //账号密码登录
    async login() {
      let msg = '';
      if (!this.account || !this.password) msg = '请输入正确的账号密码';
      if (msg) return this.$message.warning(msg);

      this.logining = true;
      //登录
      let res = await this.$api.passport.auth({ username: this.account, password: this.password });
      this.logining = false;

      if (res.status != 1) return this.$message.warning('用户名或密码不正确');
      if (this.device == 'app') {
        uni.postMessage({
          data: {
            action: 'saveUser',
            data: { username: this.account, password: this.password }
          }
        });
      }
      window.localStorage.setItem('LESSONPLAY_TOKEN', res.token);
      this.$message.success('登陆成功');

      location.href = "/";
    },
    //微信登陆
    async wechat_login() {
      const wxApiRedirectURI = `${PASSPORT_CLIENT_SERVER}/wx?redirectURI=${this.redirectURI}`
      location.href = `${WX_OPEN_SERVER}/connect/oauth2/authorize?appid=${M_APPID}&redirect_uri=${wxApiRedirectURI}&response_type=code&scope=snsapi_userinfo&state=strict#wechat_redirect`
    },

    //获取验证码
    async getPhoneCode() {
      let formRegister = this.formRegister;
      let count = this.count;

      if (!formRegister.phone || formRegister.phone.length != 11) return this.$message.warning('请先输入正确的手机号');
      count = 60;
      count -= 1;
      this.count = count;

      let that = this;
      let countTime = setInterval(() => {
        count -= 1;
        that.count = count;
        if (count == 0) {
          clearInterval(countTime);
        }
      }, 1000);
      this.$message.success('短信已发送，请注意查收');

      let res = await this.$api.account.sendSms(formRegister.phone);
      if (res.code != 1) return this.$message.error(res.msg);
      formRegister.codeGuid = res.data.PK_CodeGuid;
    },
    //验证忘记密码
    async checkCode() {
      if (this.registering) return;
      let { phone, codeGuid, code } = this.formRegister;

      let msg = ``;
      if (!phone || phone.length != 11) msg = '请输入正确的手机号';
      else if (!codeGuid) msg = '请先获取验证码';
      if (msg) return this.$message.warning(msg);

      this.registering = true;
      //先验证验证码
      let res = await this.$api.account.checkcode(codeGuid, code);
      if (res.code != 1) {
        this.registering = false;
        return this.$message.error(res.msg);
      }

      if (this.is_register) this.register();
      else {
        //找到用户
        let userList = await this.$api.account.getClientByUser(phone).then(res => res.data || []);
        this.registering = false;
        if (userList.length == 0) {
          return this.$message.warning("未找到该用户，请确认手机号是否正确");
        }
        this.userGuid = userList[0].PK_UserGuid;
        this.forgetStep = 2;
      }
    },
    //确定注册
    async register() {
      let { phone, password, checkPassword, nickName } = this.formRegister;
      let msg = '';

      if (!/^1[3456789]\d{9}$/.test(phone)) msg = '请输入正确的手机号';
      else if (!password || password.length < 6) msg = '密码不符合规则，请输入6位以上密码';
      else if (checkPassword != password) msg = '两次输入的密码不一致';
      else if (!nickName) msg = '请输入姓名';
      if (msg) return this.$message.warning(msg);

      nickName = nickName.trim();
      let res = await this.$api.account.register({ Name: phone, Phone: phone, Password: password, NickName: nickName });
      this.registering = false;
      if (res.status != 1) {
        this.formRegister.code = '';
        this.formRegister.codeGuid = '';
        return this.$message.error(res.err);
      }

      this.$message.success('注册成功');
      this.returnLogin();
    },
    //修改密码
    async editPassword() {
      let editPasswording = this.editPasswording;
      if (editPasswording) return;
      let { password, checkPassword } = this.formRegister;

      let msg = '';
      if (!password) msg = '请输入新密码';
      else if (checkPassword.length < 6) msg = '请至少输入6位密码';
      else if (!checkPassword) msg = '请再次输入新密码';
      else if (password != checkPassword) msg = '两次输入的密码不一致';

      if (msg) return this.$message.warning(msg);

      editPasswording = true;
      let res = await this.$api.account.setNewPassword({
        PK_UserGuid: this.userGuid,
        Password: password
      });
      editPasswording = false;
      if (res.status != 1) return this.$message.error(res.message);

      this.$message.success('密码重置成功');
      this.forgetStep = 1;
      this.returnLogin();
    },
    //返回登录
    async returnLogin() {
      this.is_wechat = false;
      this.is_register = false;
      this.is_forget = false;
      this.formRegister = {
        phone: '',
        password: '',
        checkPassword: '',
        nickName: '',
        codeGuid: '',
        code: ''
      }
      //隐藏二维码
      document.querySelector('iframe');
    },
    //修改标签图标
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "./login.scss";
</style>