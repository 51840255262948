import { CODE_API, ElectronAPI } from '../baseURL'
import axios from '../config'

export default {
  //获取教材单元
  textbookInduction(FK_ClassGuid) {
    return axios.get(`${CODE_API}/textbook/class/induction?FK_ClassGuid=${FK_ClassGuid}`)
  },

  //获取桌面端
  getDesktopUrl() {
    return axios.get(`${ElectronAPI}?appName=元习`)
  },
}