import {
  createStore
} from 'vuex'

const store = createStore({
  state() {
    return {
      userInfo: null,
      storeBooks: [],
      levelInfo: null,
      allAchievementList: [],
      showReturn: 'home',
      phoneMode: false,
      electronConfig: null,
      isElectron: false,

      studentVip: null,
      currentStore: null,
      storeBooks: [],
    }
  },
  mutations: {
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    studentVip(state, studentVip) {
      state.studentVip = studentVip;
    },
    storeBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    },
    levelInfo(state, levelInfo) {
      state.levelInfo = levelInfo;
    },
    allAchievementList(state, allAchievementList) {
      state.allAchievementList = allAchievementList;
    },
    showReturn(state, showReturn) {
      state.showReturn = showReturn;
    },
    phoneMode(state, phoneMode) {
      state.phoneMode = phoneMode;
    },
    isElectron(state, isElectron) {
      state.isElectron = isElectron;
    },
    electronConfig(state, electronConfig) {
      state.electronConfig = electronConfig;
    },
    setCurrentStore(state, currentStore) {
      state.currentStore = currentStore;
    },
    setStoreBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    }
  },
  actions: {},
  modules: {}
})

export default store