import { PAYORDER_API } from '../baseURL'
import axios from '../config'

export default {
  /////////////////////////订单
  //获取订单列表
  getOrderList(fk_userGuid) {
    return axios.get(`${PAYORDER_API}/payOrder/list?fk_userGuid=${fk_userGuid}`);
  },
  //下订单
  storeBookPayOrder(data) {
    return axios.post(`${PAYORDER_API}/payOrder/storeBookPayOrder`, data);
  },
  //查订单
  findPayOrder(data) {
    let { pk_payorderGuid = '', fk_publishing_id = '', system_id = '', fk_userGuid = '' } = data;
    return axios.get(`${PAYORDER_API}/payOrder/payOrder?pk_payorderGuid=${pk_payorderGuid}&fk_publishing_id=${fk_publishing_id}&system_id=${system_id}&fk_userGuid=${fk_userGuid}`);
  },
  //

  //////////////////////////优惠券
  //获取优惠券列表
  getCouponList(data) {
    let { fk_userGuid = '', status = '' } = data;
    return axios.get(`${PAYORDER_API}/coupon/list?fk_userGuid=${fk_userGuid}&status=${status}`);
  },
  //获取优惠券
  getCoupon(pk_couponGuid) {
    return axios.get(`${PAYORDER_API}/coupon/coupon?pk_couponGuid=${pk_couponGuid}`);
  },
  //修改优惠券信息
  updateCoupon(data) {
    return axios.put(`${PAYORDER_API}/coupon/coupon`, data);
  },
  //批量生成
  batchCreate(data) {
    return axios.post(`${PAYORDER_API}/coupon/batchCreate`, data);
  },
  //验证激活码
  verifyCode(data) {
    return axios.post(`${PAYORDER_API}/coupon/verifyCode`, data);
  },
  //////////////////////////vip
  //查询信息
  getStudentVip(fk_user_id) {
    return axios.get(`${PAYORDER_API}/studentVip/studentVip?fk_user_id=${fk_user_id}`);
  },

  ///////////////////////////支付
  //通联支付
  allinpay(data) {
    return axios.post(`https://syb-test.allinpay.com/apiweb/h5unionpay/unionorder`, data);
  },
  //提交订单
  submitOrder(data) {
    return axios.post(`${PAYORDER_API}/allinpay/submitorder`, data);
  },
}