<template>
  <div class="foot-box">
    <div class="foot">
      <div class="area-box">
        <div class="logo-box">
          <div class="logo">
            <img class="logo-icon" src="@/assets/images/yuanxi/nav-logo.png" alt />
          </div>
        </div>
        <div class="other-link">
          <div class="box-item" v-for="menu of menuList">
            <div class="title">{{ menu.title }}</div>
            <div class="menu-list">
              <div class="menu-item" v-for="item of menu.list" @click="chooseMenu(menu.type, item)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-box" v-if="qrcode">
      <div class="blackscreen" @click="qrcode = ''"></div>
      <div class="box-content">
        <img :src="qrcode" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'foot',
  data() {
    return {
      menuList: [
        // {
        //   title: '友情链接',
        //   type: 'link',
        //   list: [
        //   ]
        // },
      ],


      qrcode: ''
    }
  },
  mounted() {
    //监听store
    // this.$store.subscribe((res) => {
    //   if (!res) return;

    //   let { type, payload } = res;

    // })
  },
  methods: {
    //选择菜单
    chooseMenu(type, item) {
      if (type == 'link') window.open(item.url);
      else this.qrcode = require(`@/assets/images/home/qrcode/${item.name}.jpg`);
    },
    //打开出版物经营许可证
    openCode(url) {
      this.qrcode = url;
    },
    //打开链接
    openUrl(url) {
      window.open(url);
    }
  }
}
</script>
<style scoped lang="scss">
@import "./foot.scss";
</style>