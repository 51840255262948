import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import api from "@/services";

import "./assets/styles/phone.scss";
import "./assets/styles/global.scss";
import "./assets/styles/ant.scss";


const app = createApp(App);
//ant
import {
    Modal, message
} from 'ant-design-vue'

app.config.productionTip = false;

app.config.globalProperties.$api = api;
app.config.globalProperties.$message = message;
app.config.globalProperties.$Modal = Modal;
app.config.globalProperties.$nzconfirm = function nzconfirm(title, callback) {
    Modal.confirm({
        okText: '确认',
        cancelText: '取消',
        title: title,
        onOk: () => {
            return callback();
        }
    })
}

app.directive('focus', {
    mounted(el) {
        el.focus();
        const inputElement = el.querySelector('input');
        if (inputElement && !inputElement.value) {
            inputElement.focus();
        }
    }
});

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// app.use(vConsole);

app.use(ElementPlus)
app.use(Antd);
app.use(store).use(router);
app.mount('#app');

