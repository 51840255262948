<template>
  <div class="popup-box">
    <div class="blackscreen" @click="closeBox()"></div>
    <div class="box-content downIn">
      <svg class="svg-icon popup-close" @click="closeBox()">
        <use xlink:href="#icon-guanbi" />
      </svg>
      <div class="guide-item wechat-box">
        <div class="guide-text">方法一：微信公众号登录</div>
        <div class="guide-wechat">
          <div class="qr-box">
            <div>第1步：微信扫码关注</div>
            <img class="person-qrcode" src="~@/assets/images/home/qrcode/元习.jpg" alt />
            <div class="wechat">
              <img src="~@/assets/images/nav/wechat-white.png" alt />
              <span>元习公众号</span>
            </div>
          </div>
          <div class="phone-box">
            <div>第2步：点击右下角我的教材</div>
            <img src="~@/assets/images/nav/phone-preview.png" alt />
          </div>
        </div>

      </div>
      <div class="guide-item mini-box">
        <div class="guide-text">方法二：微信小程序登录</div>
        <div class="qr-box">
          <div>微信扫码元习小程序</div>
          <img class="person-qrcode" src="~@/assets/images/home/qrcode/元习小程序.jpg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "phoneGuide",
  data() {
    return {

    }
  },
  async mounted() {

  },
  methods: {
    //关闭窗口
    closeBox() {
      this.$emit('closeBox', true)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./phone-guide.scss";
</style>