<template>
  <div class="no-image">
    <infoBackground></infoBackground>
    <a-spin class="spin-box" :spinning="loading">
      <div class="order" v-if="!loading && goodsInfo">
        <div class="pay-title">购买信息</div>
        <div class="goods">
          <div class="goods-show table-head">
            <div class="goods-name">数字教材</div>
            <div>价格</div>
            <div>有效期</div>
            <div>实付</div>
          </div>
          <div class="goods-show">
            <div class="goods-name">
              <i class="i-icon goods-cover"
                :style="{ backgroundImage: 'url(' + goodsInfo.extension_info?.cover + '!260x390)' }"></i>
              <div class="goods-info">
                <div>{{ goodsInfo.name }}</div>
                <!-- <div>{{ goodsInfo.unitCount }}个章节</div> -->
              </div>
            </div>
            <div class="goods-price">￥{{ goodsInfo.price }}</div>
            <div class="goods-date">永久有效</div>
            <div class="goods-real-price">￥{{ goodsInfo.realPrice }}</div>
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="pay-model">
          <div class="pay-coupon">
            <!-- 优惠券 -->
            <div class="coupon">
              <p>优惠劵</p>
              <a-select class="coupon-select" size="large" ref="select" v-model:value="couponGuid"
                @change="handleChange" placeholder="选择优惠券" allowClear>
                <a-select-option :value="item.pk_couponGuid" v-for="item in couponList">
                  {{ item.typeName
                  }}
                </a-select-option>
              </a-select>
            </div>
            <div class="coupon">
              <p>激活码</p>
              <a-input-group compact>
                <a-input class="coupon-input" v-model:value="activeCode" placeholder="输入激活码获得优惠" />
                <a-button class="coupon-button" type="primary" :loading="isVerifying" @click="getCoupon">验证</a-button>
              </a-input-group>
            </div>
          </div>

          <div class="pay-count">
            <div class="count">
              <div class="all">
                <p>总价：</p>
                <span>￥{{ goodsInfo.price }}</span>
              </div>
              <div class="coupon" v-if="chooseCoupon">
                <p>优惠券</p>
                <span>- ￥{{ chooseCoupon.price }}</span>
              </div>
              <div class="underline"></div>
              <div class="amount">
                <p>需付金额：</p>
                <span :class="{ oldPrice: chooseCoupon }">￥{{ goodsInfo.price }}</span>
                <span v-if="chooseCoupon && goodsInfo.realPrice > -1">￥{{ goodsInfo.realPrice }}</span>
              </div>
              <a-button class="pay-btn" @click="newOrder" type="primary" :loading="isPaying">立即支付</a-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <foot v-if="!loading"></foot>
    </a-spin>
  </div>
</template>

<script>
import navroot from "@/component/public/nav/nav.vue";
import foot from "@/component/public/foot/foot.vue";
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import infoBackground from "@/component/public/info-background/info-background.vue";

import * as uuid from "uuid";

export default {
  name: 'payment',
  components: {
    navroot, infoBackground,
    VueQr, foot
  },
  data() {
    return {
      goodsGuid: '',
      system_id: '',

      couponGuid: null,
      activeCode: null,

      goodsInfo: null,
      user: null,
      chooseCoupon: null,
      intPay: null,
      currentStore: null,

      select_style: {
        "background-color": "#d6defa4a",
      },

      couponList: [],
      allStoreBooks: [],

      loading: true,
      isPaying: false,
      isVerifying: false
    }
  },
  mounted() {
    this.goodsGuid = this.$route.params.id;

    const state = this.$store.state;
    this.user = state.userInfo;
    this.allStoreBooks = state.storeBooks;
    this.currentStore = state.currentStore;

    this.getGoods();
  },
  methods: {
    //获取数字教材详情
    async getGoods() {
      const goods = this.allStoreBooks.find(item => item.pk_store_book_id == this.goodsGuid);
      goods.realPrice = goods.price;
      this.goodsInfo = goods;
      this.system_id = goods.system_id;

      this.findOrder();
    },
    //查找支付订单判断是否支付
    async findOrder() {
      let res = await this.$api.payorder.findPayOrder({ system_id: this.system_id, fk_userGuid: this.user.PK_UserGuid });
      if (res.statusCode != 200) return this.$message.error(res.error);
      if (res.data?.isPaid) return this.$router.push(`/payorder/${res.data.pk_payorderGuid}`);
      this.loading = false;
      this.getCouponList();
    },
    //获取优惠券
    async getCouponList() {
      let res = await this.$api.payorder.getCouponList({ fk_userGuid: this.user.PK_UserGuid, status: 1 });
      if (res.statusCode != 200) return;
      let data = res.data.filter(item => !item.deadline || (new Date(item.deadline) > new Date())) || [];
      data.forEach(item => {
        item.couponConfig = item.couponConfig ? JSON.parse(item.couponConfig) : '';
      });

      this.couponList = data.filter(item => item.couponType != "assign" && (item.couponConfig && item.couponConfig.system_id === this.system_id))
    },
    //选择优惠券
    handleChange() {
      let chooseCoupon = this.couponList.find(item => item.pk_couponGuid == this.couponGuid);
      this.chooseCoupon = chooseCoupon ? chooseCoupon : null;
    },
    //计算优惠后价格
    calculatePrice() {
      const chooseCoupon = this.chooseCoupon;
      if (chooseCoupon.couponType == "assign") chooseCoupon.price = this.goodsInfo.price;
      const realPrice = this.goodsInfo.price - chooseCoupon.price;
      if (realPrice < 0) realPrice = 0;
      this.goodsInfo.realPrice = realPrice;
    },
    //下订单
    async newOrder() {
      if (this.isPaying) return;
      this.isPaying = true;
      const { pk_store_id, fk_publishing_agency_id } = this.currentStore;
      const { fk_publishing_id } = this.goodsInfo;
      const chooseCoupon = this.chooseCoupon;
      //下订单
      const newOrder = {
        pk_payorderGuid: uuid.v1(),
        fk_goodsGuid: this.goodsGuid,
        fk_userGuid: this.user.PK_UserGuid,
        fk_publishing_id,
        fk_store_id: pk_store_id,
        fk_publishing_agency_id: fk_publishing_agency_id,
        system_id: this.system_id,
        type: "textbook",
        pk_couponGuid: chooseCoupon ? chooseCoupon.pk_couponGuid : ""
      }
      const res = await this.$api.payorder.storeBookPayOrder(newOrder);
      if (res.statusCode != 200) return this.$message.error(res.message);

      setTimeout(() => {
        this.$router.push(`/payorder/${res.data.pk_payorderGuid}`);
        this.isPaying = false;
      }, 1000);
    },
    //验证优惠券
    async getCoupon() {
      let activeCode = this.activeCode;
      if (!activeCode) return this.$message.warn("请先输入激活码");
      activeCode = activeCode.trim();

      if (this.isVerifying) return;
      this.isVerifying = true;

      const res = await this.$api.payorder.getCoupon(activeCode);
      this.isVerifying = false;

      if (res.statusCode != 200) return this.$message.error(res.message);
      if (!res.data) return this.$message.warn("验证失败，激活码不存在或已过期");

      const data = res.data
      if (data.status === 2) return this.$message.warn("验证失败，激活码已被使用");
      if (data.deadline && new Date(data.deadline) < new Date()) return this.$message.warn("验证失败，激活码已过期");
      const couponConfig = data.couponConfig ? JSON.parse(data.couponConfig) : null;
      if (!couponConfig || couponConfig.system_id !== this.goodsInfo.system_id) return this.$message.warn("验证失败，激活码与商品不匹配");
      this.$message.success("验证成功，优惠已经生效");
      this.chooseCoupon = data;
      this.calculatePrice();
    }
  }
}


</script>

<style scoped lang="scss">
@import "./payment.scss";
</style>