import { IMGURL, CDNURL } from "@/config/url";

function headPath(value) {
  if (!value) {
    return CDNURL + '/Public/IMG/default-avatar.png'
  }
  else if (/(http|https):\/\//.test(value)) return value
  else {
    if (value.indexOf('/') == 0) return IMGURL + value;
    else return IMGURL + '/' + value;
  }
}

export default headPath;