<template>
  <div class="phone-bar">
    <div class="bar-item" @click="chooseBar(item)" v-for="item of menuList">
      <svg class="icon-svg">
        <use :xlink:href="item.icon" />
      </svg>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "phoneBar",
  components: {},
  props: {
    returnNav: String
  },
  data() {
    return {
      menuList: [
        { name: "首页", icon: "#icon-home", url: "/home" },
        { name: "我的教材", icon: "#icon-book", url: "/mycourse" },
        { name: "个人中心 ", icon: "#icon-person", url: "/personal" },
      ],
      userInfo: null,
    }
  },
  async mounted() {
    let { userInfo } = this.$store.state;
    this.userInfo = userInfo;
  },
  methods: {
    //选择底部
    chooseBar(item) {
      if (item.name != '首页' && !this.userInfo) {
        this.$nzconfirm('是否跳转登录?', () => {
            this.$router.push('/login');
        })
      }
      else this.$router.push(item.url)
    },
  }
}
</script>

<style scoped lang="scss">
@import "./phone-bar.scss";
</style>